.ratio-container {
  position: relative;
  height: auto !important;
}
.ratio-container:after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
  padding-bottom: 56.25%;
  content: "";
}
.ratio-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-43:after {
  padding-bottom: 75%;
}
.ratio-box:after {
  padding-bottom: 75%;
}
.ratio-1:after {
  padding-bottom: 100%;
}

/* unknown ration variation */
.unknown-ratio-container > * {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
