.news-design-line:before {
  border-top: 1px solid #00A183;
  position: absolute;
  width: calc(2 * 100vh);
  left: calc(100% - 32.5px);
  content: '';
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.separator-line {
  hr {
    width: 999%;
    border-top-color: #00A183 !important;
  }
}

.news-img {
  aspect-ratio: 1.5;

  &.no-image {
    aspect-ratio: 1.5;
  }
}

.cs2news-list-result, .cs2news-list-ajax-result, .news-teaser {
  .news-item {
    transition: $transition-base;

    .link-text {
      color: $primary;
    }
    .news-img {
      transition: all .35s ease-out;
    }
    .news-img-wrap {
      transition: all .3s ease-out;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0,0,0,0);
        mix-blend-mode: multiply;
        transition: all .3s ease-out;
      }
    }

    &:hover {
      text-decoration: none;
      transition: $transition-base;
      .news-img {
        transform: scale(1.1);
      }
      .news-img-wrap {
        &:before {
          background: rgba(0,0,0,0.2);
        }
      }

      h1, h2, h3, h4, h5, h6 {
        color: $secondary;
        transition: $transition-base;
      }

      .link-text {
        color: $secondary;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='22px' viewBox='0 -960 960 960' width='22px' fill='%2300A183'%3E%3Cpath d='m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z'/%3E%3C/svg%3E");
        transition: $transition-base;
      }
    }
  }
}

.newscategory, .newsyear {
  color: $black
}

.news-detail-header {
  max-width: 855px;
  margin-left: 0;
}

.news-detail-date {
  max-width: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: -80px;

  @include media-breakpoint-down(lg) {
    margin: 0;
  }
}

.ratio-cpl {
  &:after {
    padding-bottom: 100%;
    @include media-breakpoint-down(md) {
      padding-bottom: 66.4%;
    }
  }
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}
