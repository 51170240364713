.gbaccordion-openButton,
.gbaccordion-closeButton {
  text-align: right;
}

.gbaccordion-openButton {
  display: none;
}

.gbaccordion-openButton.collapsed {
  display: block;
}

.bg-acc-item {
  margin-top: 5rem;
}

.gbaccordion-closeButton .material-icons-outlined,
.gbaccordion-openButton .material-icons-outlined {
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
}

.js-is-sticky--change {
  position:fixed !important;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  width: var(--accw);
}
