/*
delta for root geschaeftsbericht
*/

.text-lightgreen {
  color: $green1;
}
.text-darkgreen {
  color: $green2;
}

.hr-gray {
  border-color: $gray-700;
}

.hr-green {
  border-color: $green2;
}
.lead2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}

.border-bottom-grey {
  border-bottom: solid #707070 1px;
}

ol.breadcrumb {
  background: transparent;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

ol.breadcrumb a {
  color: #000;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.2rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #000;
  content: "<";
  font-weight: bold;
}
.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #000;
  content: "<";
  font-weight: bold;
}

.breadcrumb-item {
  padding-left: 0.2rem;
}
.breadcrumb-item.active {
  color: #000;
}
.breadcrumb-item.active::after {
  padding-left: 0.2rem;
  color: #000;
  content: "<";
  font-weight: bold;
}
.centered-lead-text {
  width: 60%;
  margin: auto;
  font-size: 26px;
  line-height: 30px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 3.5rem;
  margin-top: 3.5rem;
  color: #0C85CA;
  position: relative;
}

.centered-lead-text:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background: radial-gradient(circle, #0C85CA 2px, transparent 2px) repeat-x bottom;
  background-size: 16px 3px;
}
.centered-lead-text:before {
  content:"";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background: radial-gradient(circle, #0C85CA 2px, transparent 2px) repeat-x bottom;
  background-size: 16px 3px;
}
.geschaeftsbericht {

  h3,.h3 {
    font-size: 23px;
    line-height: 26px;
  }
  h4,.h4 {
    font-size: 18px;
    line-height: 26px;
  }
  h5,.h5 {
    font-size: 15px;
    line-height: 18px;
  }
  h6,.h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .lead {
    font-size: 50px;
    line-height: 70px;
  }
  .text-blue {
    color: $darkblue !important;
  }
}

@include media-breakpoint-down(md) {
  .geschaeftsbericht {

    h3,.h3 {
      font-size: 21px;
      line-height: 25px;
    }
    .lead {
      font-size: 30px;
      line-height: 38px;
    }
  }
}
