.tx-cs2-handbook {
  .purple {
    color:$purple;
  }
  .item-header-box {
    position: relative;

    .material-icons {
      font-size: 2.5rem;
      line-height: 1.2rem;
      position: absolute;
      left: 0;
      top: 4px;
    }
    .item-header {
      padding-left: 3rem;
    }
  }
    @include media-breakpoint-down(md) {

    }
}
