label,
.control-label {
  font-weight: $font-weight-bold;
  color: $text-color;
}

.custom-control-input,
.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-file-label:after {
  background: $primary;
  color: $white;
  font-family: Montserrat;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  cursor: pointer;
}

.custom-file-input {
  min-height: 37px;
  height: 100%;
}

.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  outline: none;
}

.custom-select {
  padding-right: 2.75rem;
}

.has-error {
  .form-label {
    color: $red;
  }

  .form-border-bottom,
  .hf-invalid,
  .error {
    border-color: $red;
  }

  .hs-input,
  .form-control {
    border-color: $red;

    &:focus {
      border-color: $lightblue;
    }
  }

  .hf-warning,
  .invalid-feedback {
    display: block;
    color: $red;
    font-size: .8125rem;
    padding: 0;
    margin-top: .25rem;
  }
}

.dropdown-toggle::after {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.3333rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  content: "keyboard_arrow_down";
  border: none;
  transition: transform .25s ease-in-out;
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;

  .dropdown-toggle {
    transition: none !important;
  }

  &.show {
    .dropdown-toggle {
      box-shadow: none !important;
      z-index: 1001;

      &:after {
        transform: rotate(-180deg);
      }
    }

    .inner {
      display: block;
    }
  }

  .dropdown-menu {
    padding: 0;
  }
}

.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  transition: none !important;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $gray-900;
}

.form-group {
  .custom-radio,
  .custom-checkbox {
    padding-bottom: 1rem;
    padding-left: 2.5rem;

    &:last-child {
      padding-bottom: 0;
    }

    .custom-control-label {
      font-weight: 400;

      &:before {
        width: 24px;
        height: 24px;
        border: 2px solid $blue;
        box-shadow: none;
        border-radius: 0;
        top: 0;
        left: -2.5rem;
      }

      &:after {
        width: 24px;
        height: 24px;
        top: 0;
        left: -2.5rem;
      }
    }
  }

  .custom-radio {
    .custom-control-label {
      &:before {
        border-radius: 50%;
      }
    }
  }
}

.has-error {
  .error.help-block {
    color: $red;
    padding-top: 7px;
    font-size: 0.83334rem;
  }
}

select {
  &.form-control {
    &.newsyear, &.newscategory {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  &::-ms-expand {
    display: none; // Remove default arrow in IE
  }
}

.custom-select-icon {
  position: absolute;
  top: 45px;
  right: 45px;

  @include media-breakpoint-down(md) {
    right: 35px;
  }
}
