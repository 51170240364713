.popover-content {
  &.comment,
  &.bookmark {
    min-width: 200px;
    min-height: 130px;
  }

  &.search-history {
    min-width: 100px;
    min-height: 25px;
  }
}

#subscription-modal.modal,
#newcomment-modal.modal,
#editcomment-modal.modal,
#newbookmark-modal.modal,
#editbookmark-modal.modal {
  .modal-dialog {
    max-width: 920px;
  }
}

ul.typo3-messages {
  list-style: none;
  padding: 0;
}
ul.typo3-messages p {
  margin: 0;
}
