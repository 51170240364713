.tx-cs2-lawrecords {

  #lawcontent {
    margin-top: 140px;
  }

  .material-icons-outlined {
    font-size: 25px;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }

  #preface > .-content > p {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .toc-icon {
    position: fixed;
    left: 15%;
    top: 385px;
    z-index: 1030;
    background-color: #fff;

    @include media-breakpoint-down(sm) {
      left: 20px;
      top: 95px;
      transition: all .35s ease;
      &.toc-top {
        top: 45px;
      }
    }

    @include media-breakpoint-between(md, lg) {
      left: 20px;
      top: 215px;
    }

    .material-icons-outlined {
      transform: rotate(180deg);
    }
  }

  .section {
    margin-bottom: 1rem;
    scroll-margin-top: 10rem;
  }

  .footnotes {
    font-size: 15px;
    border-top: 1px solid #00b0df;
    border-bottom: 1px solid #00b0df;
    margin-top: 65px;
    margin-bottom: 65px;

    @include media-breakpoint-down(lg) {
      margin-top: 45px;
      margin-bottom: 45px;

      a.action-collapse {
        font-size: 20px;
      }
    }

    .-indicator {
      height: 1px;
      position: relative;
      left: -35px;
    }
  }

  .heading a {
    color: #000;
  }
  .heading {
    a {
      color: #000;
      &:hover, &:focus {
        color: #00A183;
      }
    }
  }

  .toc-content {
    a {
      color: #000;
    }
    ul, li {
      list-style: none;
      padding-left: 0.5rem !important;
    }
  }

  .title-separator {
    height: 25px;
  }

  .title-separator:before {
    border-top: 1px solid #00A183;
    position: absolute;
    width: calc(3 * 100vh);
    left: auto;
    content: '';
  }

  h1.heading {
    font-size: 33px;
    margin-bottom: 30px;
    @include media-breakpoint-down(lg) {
      font-size: 29px;
      line-height: 33px;
    }
  }

  h2.heading {

  }
  h3.heading {

  }
  h6.heading {
    font-size: 18px;
  }

  .section > .collapse.show {
    margin-bottom: 65px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 45px;
    }
  }

  a.action-collapse:hover .text-open,
  a.action-collapse:hover .text-close {
    text-decoration: underline;
  }
  a.action-collapse .text-open {
    display: none;
  }
  a.action-collapse .text-close {
    display: inline;
  }
  a.action-collapse.collapsed .text-open {
    display: inline;
  }
  a.action-collapse.collapsed .text-close {
    display: none;
  }
  a.action-collapse.collapsed .material-icons-outlined {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .func-icons {
    position: absolute;
    left: -54px;

    @include media-breakpoint-down(lg) {
      position: relative;
      left: auto;
    }
  }
  .level-3 .func-icons {
    /* left: -70px; */
  }

  .func-info {
    position: absolute;
    right: -20px;

    @include media-breakpoint-down(lg) {
      position: absolute;
      right: 20px;

      .material-icons-outlined.arrow {
        display: none;
      }
    }
  }
  .func-icons a .material-icons-outlined,
  .func-info a .material-icons-outlined {
    color: #00B0DF;
  }
  .func-icons a:hover .material-icons-outlined,
  .func-info a:hover .material-icons-outlined {
    color: #00A183;
  }

  .footnotes .action-collapse {
    display: flex;
    text-decoration: none;
    align-items: center;
    font-size: 22px;
    height: 45px;
    @include media-breakpoint-down(lg) {
      .expand {
        position: absolute;
        right: 20px;
      }
    }
  }

  .footnotes:before {
    content: '*';
    font-size: 44px;
    line-height: 1.35;
    position: absolute;
    left: -10px;
    padding-top: 2px;
    color: #00B0DF;

    @include media-breakpoint-down(lg) {
      left: auto;
    }
  }

  #preface {
    > .-content > p:first-child,
    h1, h2 {
      margin: 0;
      display: inline;
      font-size: 2.7777rem;
      padding-right: 1rem;
      line-height: 1;
      background: linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,0) 20%,rgba(166,222,216,.4) 20%,rgba(166,222,216,.4) 80%,rgba(255,255,255,0) 80%,rgba(255,255,255,0) 100%);

      @include media-breakpoint-down(lg) {
        font-size: 2rem;
        line-height: 36px;
      }
    }
    h2 {
      display: inline-block !important;
    }
  }

  #toc-modal {
    .modal-header {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      width: calc(100% - 5px);
      border: 0;
      #toc-modal-label {
        line-height: 1.4rem;
        font-size: 29px;
        font-weight: 400;
      }
    }
    .modal-content {
      box-shadow: none;
      .modal-header {
        button {
          color: #00b0df;
        }
      }
    }
  }

  #info-modal,
  #showcomment-modal {
    .modal-content {
      box-shadow: none;
      .modal-header {
        padding-top: 66px;
        border: 0;

        @include media-breakpoint-down(lg) {
          padding-top: 20px;
        }

        .modal-title {
          .material-icons-outlined {
            font-size: 66px;
            color: #fff;
          }
        }

        button {
          color: #00b0df;
        }
      }

      .modal-body {
        .info-content {
          a.link-arrow {
            font-size: 22px;
          }

          .text-notes,
          .text-warnings {
            p {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }


  dl {
    /* border: 1px solid #bdc8ca; */
    /* background-color: #f7fafa; */
  }
  .ie7 dl {
    overflow: hidden;
  }
  dt,
  dd {
    box-sizing: border-box;
    padding-right: 5px;
  }
  dd ~ dt,
  dd ~ dd {
    /* border-top: 1px solid #bdc8ca; */
  }
  dt {
    float: left;
    /* width: 35%; */
    padding-bottom: 0;
  }
  .ie7 dt {
    /* padding-bottom: 10px; */
    clear: both;
  }
  dd {
    /* margin-left: 35%; */
    /* border-left: 1px dotted #bdc8ca; */
    /* background-color: #fff; */
  }
  .ie7 dd {
    /* margin-left: 40%; */
    overflow: hidden;
  }
  dd:after {
    content: "";
    display: block;
    clear: both;
  }
  /*dt*/.row2 + dd:after {
          content:none;
        }

  #info-modal,
  #showcomment-modal {
    z-index: 1 !important;

    @include media-breakpoint-down(lg) {
      background: #fff;
      z-index: 1030 !important;
    }
  }

  ul.typo3-messages {
    list-style: none;
    padding: 0;
  }
  ul.typo3-messages p {
    margin: 0;
  }


  .overview {
    .nav-tabs {
      font-size: 33px;
      font-weight: bold;
      border-bottom: 0;

      @include media-breakpoint-down(lg) {
        font-size: 29px;
      }

      .nav-item {
        &:first-child {
          .nav-link {
            border-right: 5px solid #000;
            padding: 0 1rem 0 0;
          }
        }

        .nav-link {
          border: 0;
          padding: 0 1rem 0 1rem;
          line-height: 2rem;

          &.active {
            color: $black !important;
          }
        }

      }
    }

    .tab-content {
      .item-link {
        color: $black;

        .title {
          hyphens: auto;
        }

      }
    }
  }

  img {
    &.formula {
      transform: scale(1.2);
      transform-origin: left top;
    }
  }
  .rte-table-box .contenttable tr td {
    padding: 5px 10px !important;
  }

  table {
    font-size: 0.75rem;

    tr td {
      padding: 5px 10px;
    }
    img {
      &.formula {
        transform: scale(1);
        transform-origin: left top;
      }
    }
  }
}


.popover-content {
  &.laws {
    min-width: 345px;
    min-height: 200px;

    a:hover {
      text-decoration: none !important;
    }
  }
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal .modal-dialog {
  max-width: 920px;
}

.modal#empty-modal .modal-dialog {
  max-width: 920px;
  /* top: calc(50% - 120px); */
}

.modal#share-modal .modal-dialog {
  max-width: 500px;
  /* top: calc(50% - 120px); */
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 30%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.left .modal-dialog {
  width: 410px;
  top: 385px;
  height: calc(100% - 385px);

  @include media-breakpoint-down(lg) {
    width: calc(100% - 50px);
    top: 60px;
    height: calc(100% - 60px);
  }
}

.modal.right .modal-dialog {
  width: 30%;
  @include media-breakpoint-down(lg) {
    width: 100%;
    top: 60px;
    height: calc(100% - 60px);
  }
}

.modal.right .modal-dialog,
.modal.right .modal-header,
.modal.right .modal-body {
  background-color: #DFF0F2;
}

.modal.left .modal-dialog,
.modal.left .modal-header,
.modal.left .modal-body {
  background-color: #fff;
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fadex .modal-dialog {
  left: -410px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fadex.show .modal-dialog{
  left: 0;
}

/*Right*/
.modal.right.fadex .modal-dialog {
  right: -30%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fadex.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}
.modal-backdrop {
  z-index: 0 !important;
  background-color: transparent !important;
}


.overflow-hidden {
  .toc-icon {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .sticky-wrap {
    a {
      font-size: 20px;
    }
  }
}
