.tx-cs2-newsletter {
  background: #E5F3FB;

  .subheader {
    font-size: 25px;
  }

  .radio-gap {
    gap: 30px;
  }
}
