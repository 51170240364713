.tx-cs2-accordion {
  .acc-title {
    cursor: pointer;
    span.header {
      background: rgba(166, 222, 216, 0.4);
    }
    .acc-icon {
      position: absolute;
      top: 0;
      left: -2rem;
    }
    &.collapsed {
      .acc-icon {
        transform: rotate(180deg);
      }
    }
    margin-left: 2rem;
  }
  .acc-content {
    margin-left: 2rem;
  }
}
