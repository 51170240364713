.imagefancybox {
  cursor: pointer;
}
.fancybox__container {
  .fancybox__toolbar {
    .carousel__button {
      &.fancybox__button--zoomIn {
        width: var(--carousel-button-svg-width,50%);
      }
    }
  }
  .fancybox__caption {
    font-weight: bold;
  }
}

.gallery-img {
  transition: all .3s ease-out;
  img {
    transition: all .3s ease-out;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,0);
    mix-blend-mode: multiply;
    transition: all .3s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
    &:before {
      background: rgba(0,0,0,0.2);
    }
  }
}
