.tx-cs2-ticker {
  .law-item {
    .more {
      .btn {
        font-size: 22px;
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
      }

    }
  }
  .news-item {
    .date {
      font-size: 22px;
      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }
  }
  .btn {
    &.arrow {
      font-size: 22px;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }
}
