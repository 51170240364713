//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
$bluedakrblue: linear-gradient(68.19deg, #0E77BD 6.43%, #062E48 82.53%);
$bluedakrblue180: linear-gradient(180deg, #0E77BD 6.43%, #062E48 82.53%);
$blueradial: radial-gradient(67.66% 549.35% at 50% 40%, #0070BA 0%, #002A45 100%);
$blueradialbig: radial-gradient(118.43% 44.75% at 43.78% 19.48%, #0070BA 0%, #002A45 128%);
$shadowbase: 0px 4px 4px rgba(20, 76, 114, 0.25);
$shadowteaser: 2px 4px 8px rgba(0, 0, 0, 0.12);
$shadowteaserhover: 4px 4px 10px rgba(0, 0, 0, 0.25);
$shadowbottom: 2px 2px 3px rgba(0, 0, 0, 0.12);
$shadowtext: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 10px rgba(0, 0, 0, 0.17);
$shadowinner: inset 5px 5px 50px rgba(0, 0, 0, 0.7);
$linebg: repeating-linear-gradient( 45deg, rgba(255,255,255,.06), rgba(255,255,255,.06) 1px, rgba(255,255,255,0) 2px, rgba(255,255,255,0) 7px );
$border-radius-box: 2px !default;
$text-65: rgba(0,0,0,.65);
$font-size-xs: 0.8125rem;

:root {
  --fancybox-bg: rgba(0,0,0,.8);
}

.frame-space-before-extra-small { padding-top: 10px !important; }
.frame-space-before-small { padding-top: 25px !important; }
.frame-space-before-medium { padding-top: 50px !important; }
.frame-space-before-large { padding-top: 75px !important; }
.frame-space-before-extra-large { padding-top: 100px !important; }
.frame-space-after-extra-small { padding-bottom: 10px !important; }
.frame-space-after-small { padding-bottom: 25px !important; }
.frame-space-after-medium { padding-bottom: 50px !important; }
.frame-space-after-large { padding-bottom: 75px !important; }
.frame-space-after-extra-large { padding-bottom: 100px !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { padding-top: 5px !important; }
  .frame-space-before-small { padding-top: 20px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 60px !important; }
  .frame-space-before-extra-large { padding-top: 80px !important; }
  .frame-space-after-extra-small { padding-bottom: 5px !important; }
  .frame-space-after-small { padding-bottom: 20px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 60px !important; }
  .frame-space-after-extra-large { padding-bottom: 80px !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 5px !important; }
  .frame-space-before-small { padding-top: 20px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 60px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 5px !important; }
  .frame-space-after-small { padding-bottom: 20px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 60px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  font-size: 18px;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  padding-top: calc(50px - var(--meta,0));
  transition: opacity .35s ease;
}
body.overflow-hidden {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  /*margin-top: calc(var(--meta));
  .main-header {
    margin-top: calc(var(--meta));
  }*/
}
// content wrapper
.content-wrapper {
  padding: 0 $grid-gutter-width/2;
  max-width: $max-width-content-wide;
  margin: 0 auto;
}
.element-wrapper { margin-bottom: $spacer*2}

.navigation-wrap {
  max-width: $max-width-content-wide;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto,
.container,
.container-fluid {
  padding-right: $grid-gutter-width * .5;
  padding-left: $grid-gutter-width * .5;
}

.row {
  margin-right: -$grid-gutter-width * .5;
  margin-left: -$grid-gutter-width * .5;
}
.subheader {
  color: $gray-700;
}

.text-black {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $black;
  }
  .subheader {
    color: rgba($black, 0.8);
  }
}

.text-white {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
  }
  .subheader {
    color: rgba($white, 0.8);
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  .special {
    position: relative;
    display: inline;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 20%, rgba(166, 222, 216, 0.4) 20%, rgba(166, 222, 216, 0.4) 80%, rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%);
  }
}

h1,.h1 {
  /*line-height: 2.7777rem;*/
  &.header-light {
    font-weight: $font-weight-light;
  }
}
h2,.h2 {
  /*line-height: 1.1666rem;*/
}
h3,.h3 {
  .special {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(166, 222, 216, 0.4) 40%, rgba(166, 222, 216, 0.4) 80%, rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%);
  }
}
h4,.h4 {
  .special {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(166, 222, 216, 0.4) 60%, rgba(166, 222, 216, 0.4) 80%, rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%);
  }
}
h5,.h5 {
  .special {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 85%, rgba(166, 222, 216, 0.4) 85%, rgba(166, 222, 216, 0.4) 100%);
  }
  .special2 {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 20%, rgba(166, 222, 216, 0.4) 20%, rgba(166, 222, 216, 0.4) 85%, rgba(255,255,255,0) 85%, rgba(255,255,255,0) 100%);
  }
}
.lead {
  line-height: 2rem;
}
.btn {
  outline: none;
  align-items: center;
  &.btn-primary {
    &:hover {
      background: $white;
      border-color: $primary;
      color: $primary;
    }
  }
  &.arrow {
    &:after {
      display: inline-block;
      content: "north_east";
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: inherit;
      color: inherit;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-font-feature-settings: 'liga';
      -moz-osx-font-smoothing: grayscale;
      padding-left: .6rem;
      vertical-align: -4px;
    }
  }
  &.btn-link {
    padding-left: 0;
    padding-right: 0;
    &:hover {
      color: $teal;
    }
    &:after {
      display: inline-block;
      content: "north_east";
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: inherit;
      color: inherit;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -moz-font-feature-settings: 'liga';
      -moz-osx-font-smoothing: grayscale;
      padding-left: .4rem;
      vertical-align: -4px;
    }
  }
  &.btn-info {
    background: $white;
    border-color: $white;
    color: $primary;
    box-shadow: none;
    &:hover {
      background: $gray-900;
      border-color: $white;
      color: $white;
    }
  }
}
.link-arrow {
  &:after {
    display: inline-block;
    content: "north_east";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    color: inherit;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    padding-left: .4rem;
    vertical-align: -4px;
  }
}

.link-download {
  &:before {
    display: inline-block;
    content: "file_download";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    color: inherit;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
    padding-right: .4rem;
    vertical-align: -4px;
  }
}


@include media-breakpoint-down(md) {
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto,
  .content-wrapper,
  .navigation-wrap,
  .container,
  .container-fluid {
    padding-right: $grid-gutter-width-md * .5;
    padding-left: $grid-gutter-width-md * .5;
  }
  .row {
    margin-right: -$grid-gutter-width-md * .5;
    margin-left: -$grid-gutter-width-md * .5;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: $grid-gutter-width-md * .5;
    padding-left: $grid-gutter-width-md * .5;
  }
  h1,.h1 {
    font-size: 2rem;
    /*line-height: 2rem;*/
  }
  h2,.h2 {
    font-size: 1.6111rem;
    /*line-height: 1.8333rem;*/
  }
  h3,.h3 {
    font-size: 1.3888rem;
    /*line-height: 1.6111rem;*/
  }
  h4,.h4 {
    font-size: 1.2222rem;
    /*line-height: 1.4444rem;*/
  }
  h5,.h5 {
    font-size: 1rem;
    /*line-height: 1.4444rem;*/
  }
  .lead {
    font-size: 1.2777rem;
    line-height: 1.8888rem;
  }
  .btn {
    font-size: 1.1111rem;
    line-height: 1.3888rem;
  }
}



.icon-2x {
  font-size: 1.7rem !important;
}
.material-icons {
  vertical-align: text-bottom;
}
.fa-15x {
  font-size: 1.389em;
}
.dark-bg {
  background: $gray-900;
  color: $white;
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $gray-500;
    }
  }
  .btn-primary {
    background: $white;
    border-color: $white;
    color: $primary;
    &:hover {
      background: $gray-900;
      border-color: $white;
      color: $white;
    }
  }
}

.design {
  @include media-breakpoint-down(md) {
    display: none;
    &.stay {
      display: block;
    }
  }
  position: relative;
  max-width: 1315px;
  margin: 0 auto;
  .design-blue {
    position: absolute;
    width: 333px;
    height: 38px;
    background: $purple;
    right: 0;
    top: 0;
  }
  .design-green {
    position: absolute;
    width: 207px;
    height: 70px;
    background: $green;
    right: 227px;
    top:0;
    mix-blend-mode: multiply;
  }
  &.in-flyout {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    .design-blue {
      top: -2rem;
      right: -20%;
      width: 120%;
      height: calc(100% + 6rem);
      @include media-breakpoint-down(md) {
        left: 5%;
        right: 0;
        width: auto;
      }
    }
    .design-green {
      bottom: -10rem;
      top: auto;
      height: 260px;
      left: -25%;
      width: 91.6%;
      right: 32%;
      @include media-breakpoint-down(md) {
        bottom: -11rem;
        left: 0;
        width: 95%;
        height: 280px;
      }

      &.bottom {
        left: 1px; /* pixel fix */
        height: 100%;
        bottom: 0;
        @include media-breakpoint-down(md) {
          left: 0;
        }
      }
    }
  }

}

.bg-cyan {
  background: $cyan;
}

ul.typo3-messages {
  list-style: none;
  padding: 0;
}
ul.typo3-messages p {
  margin: 0;
}

.popover {
  .close {
    cursor: pointer;
    color: $blue;
  }
}

@media (min-width: 576px) {
  .text-column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
}
.ce-bodytext {
  ol,
  ul {
    padding-left: 1.5rem;
    li {
      list-style: none;
      position: relative;
      &:before {
        width: 25px;
        height: 25px;
        content: '';
        padding-left: 8px;
        clip-path: polygon(0 0, 100% 0, 89% 100%, 16% 84%);
        background-color: #CAE7EA;
        position: absolute;
        top: 0;
        left: -1.5rem;
        z-index: -1;
      }
      &:nth-child(4n + 2) {
        &:before {
          clip-path: polygon(10% 0, 80% 0%, 90% 67%, 0 100%);
        }
      }
      &:nth-child(4n + 3) {
        &:before {
          clip-path: polygon(6% 14%, 100% 0, 89% 91%, 0 78%);
        }
      }
      &:nth-child(4n + 4) {
        &:before {
          clip-path: polygon(0 0, 87% 16%, 100% 90%, 16% 95%);
        }
      }
      + li {
        margin-top: 0.6666rem;
      }
      .btn {
        text-align: left;
      }
    }
  }
  ol {
    counter-reset: custom-counter;
    li {
      counter-increment: custom-counter;
      &:before {
        content: counter(custom-counter) ".";
        padding-left: 6px;
        width: 27px;
        height: 33px;
      }
    }
  }
  ul {
    li {
      &:before {
        content: '\2022';
      }
    }
  }
}

.search-icon-wrap {
  position: absolute;
  top: 10px;
  left: 28px;
}

.autocomplete-suggestions {
  background-color: $white;
  border: 1px solid $black;
  .autocomplete-group {
    padding: 2px 10px 2px 10px;
  }
  .autocomplete-suggestion {
    padding: 2px 10px 2px 10px;
    &:hover,
    &.autocomplete-selected {
      color: $white;
      background-color: $blue;
      a {
        color: $white;
      }
    }

  }
}


.pagination {
  li {
    height: 30px;
    line-height: 30px;
  }
  .page-number {
    padding-right: 15px;
    padding-left: 15px;
    border-left: 1px solid #00B0DF;
    border-right: 1px solid #00B0DF;

    &.active {
      font-weight: bold;
      a {
        color: $green;
      }
    }
  }
  .previous {
    border-right: 1px solid #00B0DF;
  }
  .next {
    border-left: 1px solid #00B0DF;
  }

}

.current-year {
  font-size: 50px;
}

.hyphenopoly {
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

.text-black {
  color: $black;
}

/* solr result */
.tx_solr {
  #tx-solr-faceting {
    .facets {
      font-size: 22px;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }

  .results-list {
    .results-topic {
      color: $blue;
    }

    .result-item-link:hover {
      .results-topic {
        text-decoration: underline;
        color: $link-hover-color;
      }
    }
  }

  .solr-pagination {
    font-size: 22px;
  }
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}

.results-highlight {
  font-weight: bold;
}

#exportBtn {
  top: 60px;
  right: 15px;
  z-index: 1050;
  display: none;
}

.col-md-reset {
  @include media-breakpoint-down(md) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
