.ce-teaser {

  .textimage-text {
    max-width: 325px;

    @include media-breakpoint-down(xl) {
      max-width: unset;
    }

    .link-arrow {
      font-size: 22px;
      line-height: 29px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

}
