.main-header {
  background: $white;
  transition: all .35s ease;
  .metanav {
    .dropdown-menu {
      padding: 3.6rem 0;
      margin: 0;
      width: 100vw;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.29);

      @include media-breakpoint-down(md) {
        top: 0;
      }

      &:before {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -8px;
        height: 8px;
        content: "";
        z-index: 1031;
        background: $white;
      }
    }
  }
  .metanav,
  .top-bar-section {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
      margin-right: 0;
    }
    > .nav {
      > .nav-item > .nav-link {
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .nav-item {
      position: static;
      display: flex;
      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
        position: relative;
        max-width: 100%;
      }
      a:hover {
        color: $teal;
      }

      .subtrigger {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: -1.5rem;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        transition: transform .35s ease;
        z-index: 1;
        &:hover {
          color: $teal;
        }
        &:after {
          font-size: 1.5555rem;
        }
        @include media-breakpoint-down(lg) {
          width: 41px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.125rem;
          right: 0;
          top: 24px;
          position: absolute;
        }
      }

      .dropdown-menu {
        padding: 3.6rem 0;
        margin: 0;
        width: 100vw;
        box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.29);
        &:before {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: -8px;
          height: 8px;
          content: "";
          z-index: 1031;
          background: $white;
        }
        .dropdown-item {
          padding: 0;
          font-size: 1rem;
          position: relative;
          a {
            padding: .625rem;
            @include media-breakpoint-down(lg) {
              padding: .44rem 4.625rem .44rem .625rem;
            }
            display: block;
            color: $body-color;
            text-decoration: none;
            &:hover,
            &.active {
              color: $teal;
            }
          }
          &.dropdown-submenu {
            a {
              padding-right: 4.625rem;
              &.show {
                color: $teal;
                + .subtrigger {
                  color: $teal;
                  @include media-breakpoint-down(lg) {
                    &:after {
                      transform: rotate(-90deg);
                    }
                  }
                }
              }
            }
            .subtrigger {
              width: 41px;
              height: 41px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .875rem;
              right: 0;
              &:after {
                display: block;
                content: "";
                width: 1px;
                height: 20px;
                background: $body-color;
                left: 0;
                position: absolute;
              }
              @include media-breakpoint-down(lg) {
                &:after {
                  transform: rotate(90deg);
                }
              }
            }
            &:hover {
              color: $teal;
            }
            > .dropdown-menu {
              top: 0;
              left: 100%;
            }
          }
        }
        @include media-breakpoint-down(lg) {
          position: relative !important;
          transform: translate3d(0,0,0) !important;
          box-shadow: none;
          left: 0 !important;
          padding: 0 1rem;
          &:before {
            display: none;
          }
          &.show {
            .subtrigger {
              &:after {
                transform: rotate(-180deg);
              }
            }
          }
        }
        @include media-breakpoint-down(lg) {
          max-height: 0;
          overflow: hidden;
          display: block;
          transition: max-height .35s ease;
          &.show {
            max-height: 100vh;
            transition: max-height .35s ease;
          }
        }
      }
    }
    .show {
      .dropdown-top {
        color: $teal;
      }
      > .subtrigger {
        color: $teal;
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
    .nav-link {
      color: $body-color;
      font-size: 1.2222rem;
      padding: .5rem 1.5rem;
      @include media-breakpoint-down(lg) {
        padding: 8px 45px 8px 10px;
        width: 100%;
      }
      &.active,
      &:hover {
        color: $teal;
      }
    }
  }
}

.logo {
  @include media-breakpoint-down(lg) {
    max-width: 40%;
  }
}
.hideme {
  margin-top: calc(var(--meta));
  @include media-breakpoint-up(xl) {
    box-shadow: 8px 8px 20px rgba(0, 0, 0, .26);
  }
}
.hamburger {
  @include media-breakpoint-down(lg) {
    margin-right: -.75rem;
  }
}
.logo-wrap {
  padding-top: 50px;
  padding-bottom: 40px;
  min-height: 60px;
  transition: box-shadow .35s ease;
  @include media-breakpoint-down(lg) {
    box-shadow: 8px 8px 20px rgba(0,0,0,.26);
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.metanav {
  .dropdown {
    .subtrigger,
    .nav-link {
      color: $blue;
      &:hover {
        color: $teal !important;
      }
    }
  }
  .language-selector {
    .nav-link {
      color: $blue;
      &.disabled {
        color: rgba(0, 0, 0, 0.5);
      }
      &:hover {
        color: $teal;
      }
      @include media-breakpoint-down(lg) {
        padding-top: 0;
        padding-bottom: 0;
        text-transform: initial !important;
      }
    }
  }
}
.show + .main-header {
  .logo-wrap {
    box-shadow: 8px 8px 20px rgba(0,0,0,0);
  }
}
#mainnav {
  position: static;
  @include media-breakpoint-down(lg) {
    margin-right: -20px;
  }
  > .d-flex {
    height: 100%;
  }
  &.show {
    @include media-breakpoint-down(lg) {
      height: calc((var(--vh, 1vh) * 100) + var(--meta)) !important;
      overflow-y: auto;
    }
  }
}

.closeme {
  cursor: pointer;
  color: $primary;
  position: absolute;
  top: 3.6rem;
  right: calc((100vw - 1480px) / 2);
}
.level3 {
  position: relative !important;
  box-shadow: none;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  padding: 0;
  a {
    padding: .25rem 0;
    &:hover {
      text-decoration: none;
    }
  }

}
.level2 {
  .navigation-wrap {
    > .nav-item {
      max-width: 50%;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      > a {
        font-weight: 700;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.menu-cols-4 {
  > .nav-item {
    max-width: 25% !important;
  }
}
.menu-cols-3 {
  > .nav-item {
    max-width: 33.3334% !important;
  }
}
.menu-cols-2 {
  > .nav-item {
    max-width: 50% !important;
  }
}

.menu-cols-1,
.menu-cols-2,
.menu-cols-3,
.menu-cols-4 {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
  > .nav-item {
    width: auto;
    flex: 1 1 auto;
    @include media-breakpoint-down(md) {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

.iss {
  $iss-gray-color: #D8CECF;
  $iss-issue-color: var(--issue-color, $primary);

  #mainnav.show {
    height: calc((var(--vh, 1vh)* 100) - var(--navTop)) !important;
  }

  .hamburger {
    .hamburger-box {
      width: 24px;
      height: 17px;
    }
    .hamburger-inner {

      &, &:before, &:after {
        background-color: $black;
        width: 24px;
        height: 2px;
      }

      &:after {
        top: 14px;
      }

      &:before {
        display: none;
      }

    }

    &.is-active {
      .hamburger-inner {
        &:after {
          top: 16px;
        }
      }
    }
  }

  .metanav,
  .top-bar-section {
    > .nav {
      > .nav-item > .nav-link {
        &:last-child {
          padding-right: 13px;

          @include media-breakpoint-down(lg) {
            padding-right: 25px;
          }
        }
      }
    }
  }

  .fixed-top {
    position: absolute !important;
  }

  .main-header {
    &.hideme {
      .nav {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .logo-wrap {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: auto;
      box-shadow: none !important;

      img {
        width: 120px;
        height: 70px;

        @include media-breakpoint-down(md) {
          width: 80px;
          height: 50px;
        }
      }
    }
    .nav {
      padding-top: 20px;
      padding-bottom: 20px;
      transition: $transition-base;
      .nav-item {
        margin-right: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: 70px;

        .nav-link {
          font-size: $font-size-lg;
          font-weight: $font-weight-bolder;
          line-height: 29px;
          padding: 1px 13px;
          color: $black;
        }
      }
    }

    .top-bar-section {
      margin: 0 !important;

      .nav-item {
        .nav-link {
          &:hover {
            color: $iss-issue-color;
          }

          &.active {
            background: $iss-issue-color;
            color: $white;
          }
        }
      }
    }



    @include media-breakpoint-down(md) {
      .top-bar-section {
        padding-top: 36px;

        .nav-item {
          width: fit-content;
          padding-bottom: 30px;
          padding-top: 0;

          .nav-link {
            padding: 7px 25px;
            font-weight: $font-weight-normal !important;
            border: 1px solid $iss-issue-color;
            color: $iss-issue-color;

            &:hover {
              color: $iss-issue-color;
            }

            &.active {
              background: $iss-issue-color;
              color: $white;
            }
          }
        }
      }
    }

    .metanav {
      .nav-item {
        margin-right: 12px;
        .nav-link {
          font-weight: $font-weight-normal;
        }
      }
    }
  }
  &.home {

    .breadcrumb {
      display: none !important;
    }

    padding-top: 0;

    .main-header:not(.active) {
      background: transparent;
    }
    .logo-wrap {
      .logo {
        visibility: hidden !important;
        opacity: 0 !important;
      }
      .hamburger {
        background: $white;
        padding: 10px 7px 7px 7px !important;
        opacity: 1 !important;
      }
    }
  }

  .main-header {
    .h-menu {
      @include media-breakpoint-down(md) {
        height: calc((var(--vh, 1vh)* 100) - var(--navTop)) !important;
      }
    }
    .metanav {
      .language-selector {
        &.nav-item {
          .nav-link {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            line-height: 29px;
            color: $black;
            padding-right: 8px;
            text-transform: uppercase !important;
            width: fit-content !important;

            &.active {
              font-weight: $font-weight-bolder !important;
              color: $iss-issue-color !important;
            }
          }
        }
      }
      .nav-search {
        display: flex;
        align-items: center;
        .material-icons {
          font-size: 24px !important;
          @include media-breakpoint-down(md) {
            font-size: 32px !important;
          }
        }

        &:hover {
          color: $iss-issue-color !important;
        }
      }
    }
  }

  .main-header {
    .metanav {
      .account {
        margin-left: 17px;
        position: relative;

        .btn-primary {
          color: $white;
          background-color: $iss-issue-color;
          border-color: $iss-issue-color;
        }

        .level2 {
          left: auto;
          right: 0;
          width: 370px;
          padding: 25px;
          background-color: #ECE7E7;

          .nav-item {
            padding-top: 25px;
            padding-bottom: 0;
          }

          &:before, .closeme {
            display: none;
          }
        }

        .account-title {
          font-size: $font-size-lg;
          line-height: 30px;

          padding-bottom: 9px;
        }
        .account-link {
          margin-top: 13px;

          a {
            font-size: $font-size-lg;
            line-height: 29px;
            color: $iss-issue-color;
          }
        }
        .account-content {
          border-bottom: 1px solid $iss-gray-color;
        }
        .account-footer {
          padding-top: 25px;

          a {
            &:hover {
              color: $iss-issue-color;
            }
          }
        }

        .login {
          .account-title {
            font-size: $font-size-lg;
            line-height: 30px;

            padding-bottom: 18px;
          }
          .account-content {
            padding-bottom: 35px;
          }
          .account-footer {
            padding-top: 14px;
          }
        }

        .account-icon {
          color: $black;
          margin: 0 !important;
          top: 0 !important;
          transform: none !important;
          position: relative !important;
          height: auto !important;

          &:hover {
            color: $iss-issue-color !important;
          }
          &:after {
            display: none;
          }
        }
        &.show {
          .account-icon {
            color: $iss-issue-color;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .account {
          .account-content {
            a, .account-title {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }

      @include media-breakpoint-down(xl) {
        .account {
          position: initial;
          margin: 0;

          .level2 {
            position: absolute !important;
            width: auto;
            bottom: 96px;
            top: auto;
            height: auto;
            max-height: none;
            right: 43px;
            left: 20px !important;
            padding: 17px 19px;
            display: none !important;
          }

          .level2.show {
            display: block !important;
          }
        }
      }
    }
  }

  .registration-link {
    color: $iss-issue-color;
    font-weight: 700;
    font-size: 15px;
    display: block;

    margin-top: 6px;

    &.link-arrow {
      &:after {
        content: none;
        display: none;
      }
      &:before {
        display: inline-block;
        content: "\e5cc";
        font-family: 'Material Icons';
        font-weight: 900;
        font-style: normal;
        font-size: inherit;
        color: inherit;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -moz-font-feature-settings: 'liga';
        -moz-osx-font-smoothing: grayscale;
        padding-right: .4rem;
        vertical-align: -4px;
        margin-left: -4px;
      }
    }

  }
}
