@media print {
  @page {
    size: A4 portrait;
  }
  body {
    margin-top: 45mm;
    padding-top: 0;
  }
  .no-print {
    display:none;
    visibility: hidden;
  }
  .footnotes:before {
    display:none;
    visibility: hidden;
  }
  /*
  a {
    color: #000;
    text-decoration: none;
  }
   */
  .tx-cs2-lawrecords {
    padding-top: 0;
    #lawcontent {
      margin-top: 0;
    }

    .overview {
      #overview-tab {
        page-break-before: always;
      }
      .tab-content {
        page-break-inside: auto;
      }
    }
  }

}
