.ce-textpic {

  &.layout-36 {
    .row.image-col {
      margin-top: 8px;
    }
  }

}

.ce-textimage {
  .image {
    padding-top: 8px;
  }
}
