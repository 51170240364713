.main-footer {
  padding: 0;
  background: $white;
  .nav-wrapper {
    padding: 1rem 15px;
    margin: 0 47px;
  }
  #footerbar {
    background: $gray-800;
  }
  .footer-logo {
    height: auto;
    @include media-breakpoint-down(md) {
      max-width: 160px;
    }
  }
  .footbar {
    .nav-link {
      color: $primary !important;
      padding: .5rem .25rem;
    }
    .nav-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      color: $primary;
    }
    .nav-item + .nav-item {
      &:before {
        display: inline-block;
        content: "|";
        padding: .5rem .125rem;
        color: inherit;
      }
    }
  }
  .santelinks {
    .nav-item {
      .nav-link {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .social {
    .nav-item {
      .nav-link {
        padding-left: 9px;
        padding-right: 9px;
      }
    }
  }
  .holder {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include media-breakpoint-down(md) {
  .footertopbar {
    .frame-space-before-medium {
      padding-top: 28px !important;
    }
    .frame-space-after-medium {
      padding-bottom: 35px !important;
    }
  }
}

.tx-cs2-footer {
  .footerbar-logo {
    height: 62px;
    margin-right: 60px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 28px;
      height: 56px;
    }
  }

  @include media-breakpoint-down(md) {
    .footer-text {
      margin-top: 98px;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.iss {
  .main-footer {
     margin-top: 220px;

    .footbar {
      .nav-link {
        color: $blue !important;
        padding: .5rem .25rem;
      }
      .nav-item {
        color: $blue;
      }
    }
  }
}
